import React from 'react';
import { Link } from 'gatsby';
import TemplateWrapper from '../components/TemplateWrapper';

const NotFoundPage = () => (
  <TemplateWrapper className='notfound-page'>
    <article className='sheet'>
      <div className='sheet__inner'>
        <h1 className='sheet__title'>Page not found</h1>
        <div className='sheet__body'>
          <p>
            <Link to='/'>Click here to go back home</Link>
          </p>
        </div>
      </div>
    </article>
  </TemplateWrapper>
);

export default NotFoundPage;
